<script setup lang="ts">
import icon_pay_success from '~/assets/image/icon/icon_pay_success.png'

const onClickLeft = () => history.back()
</script>

<template>
  <div h-screen>
    <van-nav-bar title="支付结果" left-arrow @click-left="onClickLeft" />
    <div mt-10 h-50 w-full flex flex-col items-center justify-center>
      <img h-15 w-15 :src="icon_pay_success" />
      <span text-lg ct-e8e8e8>支付成功</span>
    </div>
    <div mt-4 flex justify-center>
      <div h-11 w-88 rounded-md text-center leading-11 bg-414660 ct-ffffff @click="onClickLeft">返回</div>
    </div>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
</style>
